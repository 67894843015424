@font-face {
    font-family: 'OttoAttackType';
    src: url(../../fonts/OTTOATTACTYPE.ttf) format('truetype');
}

@font-face {
    font-family: 'RageBrutalita';
    src: url(../../fonts/Rage_Brutalita_V2/RageBrutalitaIIMono-400.otf) format('opentype');
}

.contact {
    font-size: 1.5em;
}

h2 {
    font-family: 'OttoAttackType', serif;
    letter-spacing: .1em;
}

a,
.name {
    font-family: 'RageBrutalita', monospace;
    font-size: .75em;
    text-decoration: none;
}

.name {
    color: #ffffff;
}

a:hover,
a:active{
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .contact {
        font-size: 1em;
    }
}