.glitchImageParent {
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    position: relative;
}

.glitchImage {
    margin: 0px;
    width: 100%;
    height: 100%;
    left: 0px;
    right: 0px;
}

.glitchImage img {
    position: absolute;
    transform: translate(-50%, -50%);
}