@font-face {
    font-family: 'OttoAttackType';
    src: url(../../fonts/OTTOATTACTYPE.ttf) format('truetype');
}

@font-face {
    font-family: 'RageBrutalita';
    src: url(../../fonts/Rage_Brutalita_V2/RageBrutalitaIIMono-400.otf) format('opentype');
}

.textBox {
    margin: none;
    /* font-family: monospace; */
    font-family: 'RageBrutalita';
    text-align: center;
    position: relative;
    line-height: 1.42;
    font-size: 1.5em;
    width: 74vw;
    left: 13vw;
}


/* .textBox.wide {
    width: 69vw;
    left: 15.5vw;
} */

.highlight {
    font-family: 'OttoAttackType';
    font-size: 1.21em;
    letter-spacing: .1em;
    line-height: 1.42;
}

@media only screen and (max-width: 768px) {
    .textBox {
        width: 84vw;
        left: 8vw;
        font-size: 1em;
    }
}