@font-face {
    font-family: 'OttoAttackType';
    src: url(../../fonts/OTTOATTACTYPE.ttf) format('truetype');
}

@font-face {
    font-family: 'RageBrutalita';
    src: url(../../fonts/Rage_Brutalita_V2/RageBrutalitaIIMono-400.otf) format('opentype');
}

p {
    color: #ffffff;
    font-size: .69em;
    font-family: 'RageBrutalita', monospace;
    width: 100vw;
}

a {
    font-family: 'OttoAttackType', serif;
    color: #00ff33;
    font-size: 1em;
    letter-spacing: .1rem;
}